
import React from 'react'
import $ from 'jquery';
import { Link } from 'react-router-dom';



function Sidebar() {

  $(function () {
    $("a").on("click", function (e) {
      // hide any open menus and remove all other classes
      $("ul", $(this).parents("ul:first")).removeClass("in");
      $("a", $(this).parents("ul:first")).removeClass("active");
      const isActive = $(this).hasClass("active");
    
      if (!isActive) {
        // open our new menu and add the open class
        $(this).next("ul").addClass("in");
        $(this).addClass("active");

      } else if (isActive) {
        $(this).removeClass("active");
        $(this).parents("ul:first").removeClass("active");
        $(this).next("ul").removeClass("in");

      }
    });

    $("#sidebarnav >li >a.has-arrow").on("click", function (e) {
      e.preventDefault();
    });
  });


  return (

    <aside className="left-sidebar" data-sidebarbg="skin5">
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <ul id="sidebarnav" className="pt-1">
            <li className="sidebar-item">
              <input className='form-control form-control-sm bg-dark text-white border border-secondary w-100' placeholder='Search..' />
            </li>
            <li className="sidebar-item">
              <Link title="Dashboard"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                to={"/dashboard"}
                aria-expanded="false"
              > <i className="fa fa-tachometer"> </i><span className="hide-menu">Dashboard</span></Link>
            </li>
            <li className="sidebar-item">
              <Link
                title="Client Profile"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                to={"/clientprofile"}
                aria-expanded="false"
              ><i className="fa fa-users"></i><span className="hide-menu">Client Profile</span></Link>
            </li>
            <li className="sidebar-item">
              <Link title="Front Office"
                className="sidebar-link waves-effect waves-dark sidebar-link"
                to={"/frontoffice"}
                aria-expanded="false"
              ><i className="fa fa-address-book"></i><span className="hide-menu">Front Office</span></Link>
            </li>

            <li className="sidebar-item">
              <a title="Back Office"
                id="submenu"
                className="sidebar-link has-arrow waves-effect waves-dark"
                href="javascript:void(0)"
                aria-expanded="false"
              ><i className="fa fa-book"></i><span className="hide-menu">Back Office</span></a>
              <ul aria-expanded="false" className="collapse first-level">
                <li className="sidebar-item">
                  <Link title="General Journal"
                    to={"/generaljournal"} className="sidebar-link"
                  ><i className="fa fa-sticky-note"></i><span className="hide-menu"> General Journal </span></Link>
                </li>
                <li className="sidebar-item">
                  <Link title="Disbursement"
                    to="/disbursement" className="sidebar-link"
                  ><i className="fa fa-credit-card-alt"></i><span className="hide-menu"> Disbursement </span></Link>
                </li>
                <li className="sidebar-item">
                  <Link title="Bills Payment"
                    to="/billpayment" className="sidebar-link"
                  ><i className="fa fa-credit-card"></i><span className="hide-menu"> Bills Payment </span></Link>
                </li>
                <li className="sidebar-item">
                  <Link
                    title="Cash Receipts"
                    to={"/cashreceipt"} className="sidebar-link"
                  ><i className="fa fa-money"></i><span className="hide-menu"> Cash Receipts </span></Link>
                </li>
              </ul>
            </li>
            <li className="sidebar-item">
              <a title="Reports"
                id="submenu"
                className="sidebar-link has-arrow waves-effect waves-dark"
                href="javascript:void(0)"
                aria-expanded="false"
              ><i className="fa fa-file-text"></i><span className="hide-menu">Reports</span></a>
              <ul aria-expanded="false" className="collapse first-level">
                <li className="sidebar-item">
                  <Link title="Customized"
                    to={"/customreports"} className="sidebar-link"
                  ><i className="fa fa-pencil-square-o"></i><span className="hide-menu"> Customized </span></Link>
                </li>

              </ul>
            </li>

          </ul>

        </nav>

      </div>

    </aside>




  )
}

export default Sidebar;