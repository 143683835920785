import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function ReportPdf() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const prevClick = (e) => {
        e.preventDefault();

        var prevNum = pageNumber - 1
        if (prevNum < 1) {
            return;
        }
        setPageNumber(prevNum);
    }

    const nextClick = (e) => {
        e.preventDefault();
        var NextNum = pageNumber + 1
        if (numPages < NextNum) {
            return;
        }
        setPageNumber(NextNum);
    }
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    return (
        <div className='bg-dark text-dark px-2 pt-2'>

            <Document file={"./sample.pdf"} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
            </Document>

            <div id='toppageviewer' className='text-white' >
                <div className='mt-3'>
                    <div className='row' >
                        <div className='col-md-3 text-end'>
                            <p className='text-sm'> Page {pageNumber} of {numPages}</p>
                        </div>
                        <div className='col-md-3 text-start'>
                            <a href='#'className='text-white' ><i class="fa fa-print fa-lg" aria-hidden="true"></i></a>
                            <a href='#'className='text-white zoom-plus' ><i class="fa fa-search-plus fa-lg" aria-hidden="true"></i></a>
                            <a href='#'className='text-white zoom-minus' ><i class="fa fa-search-minus fa-lg" aria-hidden="true"></i></a>
                        </div>
                        <div className='col-md-3 text-start'>
                            <a onClick={prevClick} href='#' className='text-white' title='prev'> <i class="fa fa-arrow-circle-left fa-2x" aria-hidden="true"></i> </a>
                            <a onClick={nextClick} href="#" className='text-white' title='next'><i class="fa fa-arrow-circle-right fa-2x" aria-hidden="true"></i></a>
                        </div>
                    </div>

                </div>


            </div>
            1

        </div>
    )
}

export default ReportPdf