import React from 'react'

function GLSLEntriesTab() {
    return (
        <div className='row px-3 '>
            <div className='col-md-12 border'>
                <table className=' styled-table w-100'>
                    <thead className='table-grid'>
                        <tr>
                            <th>SC</th>
                            <th>Acct. Description</th>
                            <th>G/L Account Code</th>
                            <th>Account Title</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Client ID.</th>
                            <th>Name of Client</th>
                            <th>Acct No.</th>
                            <th>F</th>
                            <th>S/L Date</th>
                            <th>Encoded By</th>
                            <th>Approved By</th>
                        </tr>
                    </thead>
                    <tbody className='table-grid-d'>
                        <tr>
                            <td><br /></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='col-md-12 border' >
                <div className='row pt-4 pb-4'>
                    <div className='col-md-4'>
                        
                        <div className='row'>
                            <div className='col-md-12'>
                                <label for="fname" className="w-100 control-label col-form-label-md text-end ">Difference</label>
                            </div>
                            <div className='col-md-12'>
                                <label for="fname" className="w-100 border border-secondary px-1 control-label col-form-label-md text-end">0.00</label>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                      
                        <div className='row'>
                            <div className='col-md-12'>
                                <label for="fname" className="w-100 control-label col-form-label-md text-end ">Debit</label>
                            </div>
                            <div className='col-md-12'>
                                <label for="fname" className="w-100 border border-secondary px-1 control-label col-form-label-md text-end">0.00</label>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                    <div className='row'>
                            <div className='col-md-12'>
                                <label for="fname" className="w-100 control-label col-form-label-md text-end ">Credit</label>
                            </div>
                            <div className='col-md-12'>
                                <label for="fname" className="w-100 border border-secondary px-1 control-label col-form-label-md text-end">0.00</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default GLSLEntriesTab