import React from 'react'
import { Link } from 'react-router-dom'

function ClientProfileSearchContnent() {
  return (
    // <div className="container-fluid">
    //         <Link to={'/clientprofile'}>Back</Link>
    // </div>
    <div className="container-fluid">
      Search: <input type="text" className='w-25' placeholder='Search Here...'  /> 
        <button className='btn btn-info btn-sm mx-1 mt-1'><i class="fa fa-search" aria-hidden="true"></i> Go Filter</button>
        <Link className='btn btn-danger btn-sm  mx-1 mt-1' to={'/clientprofile'}> <i class="fa fa-times" aria-hidden="true"></i> Cancel</Link>
      <div className='card'>
   
        <div className='card-body'>
    
          <div className="">
            <table

              className="styled-table w-100"
            >
              <thead>
                <tr>
                  <th>Client ID</th>
                  <th>Client Name</th>
                  <th>Middle Name</th>
                  <th>Client Type</th>
                  <th>Status</th>
                  <th>Department</th>
                  <th>Created At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className='table-grid-d'>
                <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
                <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
                <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
                <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
                  <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
                <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
                <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
                <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
                <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
                <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
                <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
                <tr>
                  <td>0000005</td>
                  <td>Juan Dela Cruz S.</td>
                  <td>Santos</td>
                  <td>Regular Indiv.</td>
                  <td>Active</td>
                  <td>Default</td>
                  <td>2023-1-3 3:35:22</td>
                  <td> <Link className='btn btn-sm btn-success  btn-view'><i class="fa fa-eye" aria-hidden="true"></i></Link> </td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>

      </div>

    </div>
  )
}

export default ClientProfileSearchContnent