import React from 'react'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'
import ClientProfileSearchContnent from '../../contents/ClientProfile/ClientProfileSearchContnent'
import ReportViewer from '../../layouts/ReportViewer'




function ClientProfileSearch() {
  return (
    <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
>
    <Header title="Client Profile : Search" />

    <div>
        <Sidebar />

        <div className="page-wrapper">

            <ClientProfileSearchContnent/>
            <ReportViewer/>
        </div>
    </div>

</div>
  )
}

export default ClientProfileSearch