import React from 'react'

function SoureceTab() {
  return (
    <div class="card">
                    <table          
                      class="styled-table"
                    >
                      <thead>
                        <tr>
                          <th>Source</th>            
                          <th>Amout</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Tiger Nixon</td>
                     
                          <td>320,800</td>
                        </tr>
                        <tr>
                          <td>Garrett Winters</td>
                     
                          <td>170,750</td>
                        </tr>
                        <tr>
                          <td>Ashton Cox</td>
                    
                          <td>86,000</td>
                        </tr>
                       
                      </tbody>
                   
                    </table>
                  </div>
  )
}

export default SoureceTab