import React, { useState } from 'react'
import ClientInfoOF from '../../components/frontoffice/ClientInfoOF'
import DisburseTab from '../../components/frontoffice/Tabs/DisburseTab'
import EncashmentTab from '../../components/frontoffice/Tabs/EncashmentTab'
import OtcVoucherTab from '../../components/frontoffice/Tabs/OtcVoucherTab'
import ReceiptTab from '../../components/frontoffice/Tabs/ReceiptTab'
import SummaryTab from '../../components/frontoffice/Tabs/SummaryTab'
import TransferFromTab from '../../components/frontoffice/Tabs/TransferFromTab'
import TransferToTab from '../../components/frontoffice/Tabs/TransferToTab'
import WithdrawalTab from '../../components/frontoffice/Tabs/WithdrawalTab'
import TopMenuOF from '../../components/frontoffice/TopMenuOF'

function FrontOfficeContent() {

  const  [displayClient,setdisplayClient] = useState(false)

  return (
    <div className="container-fluid ">
    
      <div className='row '>
      <div className='col-md-12 bg-light'>
      <TopMenuOF />
      </div>
        
        { displayClient ? <ClientInfoOF /> : null }
  
        <div className='col-md-12 bg-light'>

          <div class="container-fluid">
            <ul className="nav nav-tabs mt-1 mx-2" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <a onClick={() => setdisplayClient(false)} className="nav-link active" id="summary-tab" data-bs-toggle="tab" data-bs-target="#summary" type="button" role="tab" aria-controls="summary" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Summary</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a onClick={() => setdisplayClient(true)} className="nav-link" id="receipt-tab" data-bs-toggle="tab" data-bs-target="#receipt" type="button" role="tab" aria-controls="receipt" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Receipt</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a onClick={() => setdisplayClient(true)}  className="nav-link" id="disburse-tab" data-bs-toggle="tab" data-bs-target="#disburse" type="button" role="tab" aria-controls="disburse" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Disburse</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a onClick={() => setdisplayClient(true)}  className="nav-link" id="widthrawal-tab" data-bs-toggle="tab" data-bs-target="#widthrawal" type="button" role="tab" aria-controls="widthrawal" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Widthrawal</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a onClick={() => setdisplayClient(true)}  className="nav-link" id="encashment-tab" data-bs-toggle="tab" data-bs-target="#encashment" type="button" role="tab" aria-controls="encashment" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Encashment</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a onClick={() => setdisplayClient(true)}  className="nav-link" id="otcvoucher-tab" data-bs-toggle="tab" data-bs-target="#otcvoucher" type="button" role="tab" aria-controls="otcvoucher" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">OTC Voucher</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a onClick={() => setdisplayClient(false)}  className="nav-link" id="transferfrom-tab" data-bs-toggle="tab" data-bs-target="#transferfrom" type="button" role="tab" aria-controls="transferfrom" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Transfer From</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a onClick={() => setdisplayClient(false)}  className="nav-link" id="transferto-tab" data-bs-toggle="tab" data-bs-target="#transferto" type="button" role="tab" aria-controls="transferto" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Transfer To</span></a>
              </li>

            </ul>

            <div className="tab-content bg-white" id="myTabContent">
              <div className="tab-pane fade show active" id="summary" role="tabpanel" aria-labelledby="summary-tab"><SummaryTab /></div>
              <div className="tab-pane fade" id="receipt" role="tabpanel" aria-labelledby="receipt-tab"><ReceiptTab /></div>
              <div className="tab-pane fade" id="disburse" role="tabpanel" aria-labelledby="disburse-tab"><ReceiptTab /></div>
              <div className="tab-pane fade" id="widthrawal" role="tabpanel" aria-labelledby="widthrawal-tab"><ReceiptTab /></div>
              <div className="tab-pane fade" id="encashment" role="tabpanel" aria-labelledby="encashment-tab"><EncashmentTab /></div>
              <div className="tab-pane fade" id="otcvoucher" role="tabpanel" aria-labelledby="otcvoucher-tab"><ReceiptTab /></div>
              <div className="tab-pane fade" id="transferfrom" role="tabpanel" aria-labelledby="transferfrom-tab"><TransferFromTab /></div>
              <div className="tab-pane fade" id="transferto" role="tabpanel" aria-labelledby="transferto-tab"><TransferToTab /></div>
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default FrontOfficeContent