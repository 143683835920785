import React from 'react'
import $ from 'jquery'
import ReportPdf from '../components/ReportPdf';

function ReportViewer() 
{
  const closeRightPanelClick = () => {
    $(".panel-right-wrap").css("transform", "");
    $(".panel-right-wrap").css("width", "50%");

    $('#view-icon_arrow').addClass('fa-arrow-left');
    $('#view-icon_arrow').removeClass('fa-arrow-right');
  }
  const fullscreenClick = () => {

    var class_name = $("#view-icon_arrow").attr('class');

    if (class_name === 'fa fa-arrow-left') {
      $(".panel-right-wrap").css('width', '100%')

      $('#view-icon_arrow').addClass('fa-arrow-right');
      $('#view-icon_arrow').removeClass('fa-arrow-left');

    } else {
      $(".panel-right-wrap").css('width', '50%')

      $('#view-icon_arrow').addClass('fa-arrow-left');
      $('#view-icon_arrow').removeClass('fa-arrow-right');
    }

  }

  return (
    <div className="panel-right-wrap">
      <div className="panel-right">
        <div className='row right-header'>
          <div className='col-md-6 text-start'>

            <a href='#' onClick={fullscreenClick} title='Fullscreen'>
              <i className="fa fa-arrow-left" id="view-icon_arrow" aria-hidden="true"></i>

            </a>
          </div>
          <div className='col-md-6 text-end'>
            <a href='#' onClick={closeRightPanelClick} title='Close'>
              <i className="fa fa-window-close-o" id="view-icon_close" aria-hidden="true"></i>

            </a>
          </div>
<div className='col-md-12'>
    <ReportPdf/>
</div>
          
        </div>
      </div>
    </div>
  )
}

export default ReportViewer