import React from 'react'
import ClientPanel from '../../global/ClientPanel'

function GjTopMenu() {
    return (

        <div className='row mt-2 mb-2'>
            <div className='col-9 col-sm-12 col-md-6'>
                            <button className='btn btn-sm btn-info text-white mx-1'>
                                <i className="fa fa-search" aria-hidden="true"></i>  Search
                            </button>
                            <button className='btn btn-sm btn-success text-white mx-1'>
                                <i className="fa fa-save" aria-hidden="true"></i> Save
                            </button>
                            <button className='btn btn-sm btn-danger mx-1'>
                                <i className="fa fa-times" aria-hidden="true"></i> Cancel
                            </button>

                        </div>
            <div className='col-3 col-sm-6 col-md-6 text-end'>
                <label className='bg-primary text-center text-white h2 btn-sm'>
                    POSTED
                </label>
            </div>

        <ClientPanel/>
        
         
        </div >

    )
}

export default GjTopMenu