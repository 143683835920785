import React from 'react'
import moment from "moment";

function GetCurrentDateTime() {
  
  return (
    <>{moment().format("MMMM-DD-YYYY hh:mm:ss a")}</>
  )
}

export default GetCurrentDateTime