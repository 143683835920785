import React from 'react'
import ReferencePanel from '../../global/ReferencePanel'
import CashDom from '../ReceiptTab/CashDom'
import CociDetails from '../ReceiptTab/CociDetails'
import GlEntry from '../ReceiptTab/GlEntry'

function ReceiptTab() {
    return (
        <div className='row pt-2'>
            <div className='col-md-12'>
                <ReferencePanel />
            </div>
            <div className='col-md-4'>

                <div className='row'>

                    <div className='col-md-12'>
                    <table className='w-100'>
                    <thead>
                        <tr>
                            <th className='col-8'>
                                <div className='bg-cash text-white text-center'>
                                    Summary of Accounts
                                </div>
                            </th>
                            <th className='col-4'>
                                <div className='bg-coci text-white text-center '>
                                    Allocations
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className='table-body'>
                        <tr>
                            <td> <div className='row px-3'>
                                <div className='col-md-6 text-start'>
                                    <label className=" control-label col-form-label-sm">Loan Accounts</label>
                                </div>
                                <div className='col-md-6 text-end'><label className=" control-label col-form-label-sm">30,000.00</label></div>
                            </div>
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm text-end" />
                            </td>
                        </tr>
                        <tr>
                            <td> <div className='row px-3'>
                                <div className='col-md-6 text-start'>

                                    <label className=" control-label col-form-label-sm">A/R Accounts</label>
                                </div>
                                <div className='col-md-6 text-end'><label className=" control-label col-form-label-sm">400.00</label></div>
                            </div>
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm text-end" />
                            </td>
                        </tr>

                        <tr className='border-top'>
                            <td> <div className='row px-3'>
                                <div className='col-md-6 text-start'>
                                    <label className=" control-label col-form-label-sm">Pledges</label>
                                </div>
                                <div className='col-md-6 text-end'> <label className=" control-label col-form-label-sm">0.00</label></div>
                            </div>
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm text-end" />
                            </td>
                        </tr>
                        <tr>
                            <td> <div className='row px-3'>
                                <div className='col-md-6 text-start'>
                                    <label className=" control-label col-form-label-sm">Share Capital </label>
                                </div>
                                <div className='col-md-6 text-end'>       <label className=" control-label col-form-label-sm">8,000.00</label></div>
                            </div>
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm text-end" />
                            </td>
                        </tr>
                        <tr>
                            <td> <div className='row px-3'>
                                <div className='col-md-6 text-start'>
                                    <label className=" control-label col-form-label-sm">Savings Deposit</label>
                                </div>
                                <div className='col-md-6 text-end'><label className=" control-label col-form-label-sm">1,491.00</label></div>
                            </div>
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm text-end" />
                            </td>
                        </tr>
                        <tr>
                            <td> <div className='row px-3'>
                                <div className='col-md-6 text-start'>
                                    <label className=" control-label col-form-label-sm">Time Deposit</label>
                                </div>
                                <div className='col-md-6 text-end'><label className=" control-label col-form-label-sm">0.00</label></div>
                            </div>
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm text-end" />
                            </td>
                        </tr>
                        <tr>
                            <td> <div className='row px-3'>
                                <div className='col-md-6 text-start'>
                                    <label className=" control-label col-form-label-sm">Other Accounts</label>
                                </div>
                                <div className='col-md-6 text-end'><label className=" control-label col-form-label-sm">0.00</label></div>
                            </div>
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm text-end" />
                            </td>
                        </tr>
                    </tbody>


                </table>
                    </div>
                    <div className='col-md-12'>
                        <table className='w-100 mt-1'>

                            <thead>
                                <tr>
                                    <th>
                                        <div className='bg-total text-white text-center '>
                                            Explanation
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                <tr>
                                    <td>
                                        <textarea className='form-control form-control-sm' placeholder='Write Explanation' rows={2} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            


            </div>
            <div className='col-md-8'>

                <div className='col-md-12'>
                    <ul className="nav nav-tabs" id="myTab1" role="tablists">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="glentry-tab" data-bs-toggle="tab" data-bs-target="#glentry" type="button" role="tab" aria-controls="glentry" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">GL/SL Entry</span></a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link " id="cocidetails-tab" data-bs-toggle="tab" data-bs-target="#cocidetails" type="button" role="tab" aria-controls="cocidetails" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">COCI Details</span></a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="cashdom-tab" data-bs-toggle="tab" data-bs-target="#cashdom" type="button" role="tab" aria-controls="cashdom" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Cash Denomiation</span></a>
                        </li>

                    </ul>

                    <div className="tab-content" id="myTab1Content">
                        <div className="tab-pane fade show active" id="glentry" role="tabpanels" aria-labelledby="glentry-tab"><GlEntry /></div>
                        <div className="tab-pane fade " id="cocidetails" role="tabpanels" aria-labelledby="cocidetails-tab"><CociDetails /></div>
                        <div className="tab-pane fade " id="cashdom" role="tabpanels" aria-labelledby="cashdom-tab"><CashDom /></div>
                    </div>

                </div>

            </div>
        </div>


    )
}

export default ReceiptTab