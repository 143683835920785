import React from 'react'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'

import CashReceiptContent from '../../contents/BackOffice/CashReceiptContent'
import ReportViewer from '../../layouts/ReportViewer'

function CashReceipt() {
    return (
        <div
            id="main-wrapper"
            data-layout="vertical"
            data-navbarbg="skin5"
            data-sidebartype="full"
            data-sidebar-position="absolute"
            data-header-position="absolute"
            data-boxed-layout="full"
        >

            <Header title="Cash Receipts" />
            <div>
                <Sidebar />
                
                <div className="page-wrapper">
                    <CashReceiptContent />
                    <ReportViewer/>
                </div>
            </div>
        </div>
    )
}

export default CashReceipt