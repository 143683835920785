import React from 'react'

function SpouseTab() {
  return (
    <div className='row px-4 pt-3'>

      <div className='col-md-4'>
        <div className='row'>
          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Last Name:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>
    
          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">First Name:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Middle Name:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Suffix:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-12'>
              <br/>
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Date of Birth:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="date" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Gender:
            </label>
          </div>

          <div className='col-md-8'>
          <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Occupation:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Monthly Income:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Educational Attain:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Degree/Course.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


        </div>


      </div>

      <div className='col-md-8'>
          <div className='row'>
          <div className='col-md-2'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Business/Employer:
            </label>
          </div>

          <div className='col-md-10'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-12'>
              <div className='row'>
                    <div className='col-md-6'>
                          <div className='row'>
                          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Nature.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">No of Employees.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Since (year).:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


<div className='col-md-12'>
<br/>
</div>

<div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Job Title:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Job Status:
            </label>
          </div>

          <div className='col-md-8'>
          <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Employement Level:
            </label>
          </div>

          <div className='col-md-8'>
          <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Date Hired:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="date" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Other Income.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>




                          </div>
                    </div>
                    
                    <div className='col-md-6'>

                    </div>
              </div>

          </div>



          </div>
      </div>

     
    </div>
  )
}

export default SpouseTab