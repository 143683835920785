import React from 'react'

function ClientPanel() {
    return (
        <div className='row'>
            <div className='col-md-3 col-6 bg-light'>
                <div className='row '>
                    <div className='col-md-5 col-5'>
                        <img id="img-client" src='../assets/images/users/1.jpg' className='border border-secondary' style={{ width:"60px" }}  />
                    </div>
                    <div className='col-md-7 col-12'>
                        <table>
                            <tbody>
                                <tr>
                                    <td> <label className='control-label col-form-label-md '> Coco Martin</label></td>
                                </tr>
                                <tr>
                                    <td> <label className='control-label col-form-label-sm'> 000-000-0001</label></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='col-md-9 col-6 bg-light pt-1'>
                <div className='row'>
                    <div className='col-md-4 col-sm-4 col-12 '>
                        <table>
                            <tbody>
                                <tr>
                                    <td className='text-end  control-label col-form-label-sm'>Reference ID:  </td> <td className=' control-label col-form-label-sm'><b>12345678890</b></td>
                                </tr>
                                <tr>
                                    <td className='text-end control-label col-form-label-sm'>Department :</td> <td className='control-label col-form-label-sm'><b>Credit Department.</b></td>
                                </tr>
                                <tr>
                                    <td className='text-end  control-label col-form-label-sm'>Client Type:</td> <td className=' control-label col-form-label-sm'><b>Regular Indiv.</b></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className='col-md-4 col-sm-4 col-12 text-start'>
                        <table>
                            <tbody>
                                <tr>
                                    <td className='text-end  control-label col-form-label-sm'>Acct Type:</td> <td className=' control-label col-form-label-sm'><b>Individual</b></td>
                                </tr>
                                <tr>
                                    <td className='text-end  control-label col-form-label-sm'>Alert Level:</td> <td className=' control-label col-form-label-sm'><b>OK</b></td>
                                </tr>
                                <tr>
                                    <td className='text-end  control-label col-form-label-sm'>Date Opened:</td> <td className=' control-label col-form-label-sm'><b>Mar-1-2023</b></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className='col-md-4 col-sm-4 col-12 text-start'>
                        <table>
                            <tbody>

                                <tr>
                                    <td className='text-end  control-label col-form-label-sm'>Client Status:</td> <td className=' control-label col-form-label-sm'><b>Active</b></td>
                                </tr>
                                <tr>
                                    <td className='text-end  control-label col-form-label-sm'>Joint Type:</td> <td className=' control-label col-form-label-sm'><b>NONE</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>



        </div>

    )
}

export default ClientPanel