import React from 'react'

function ReferencePanel() {
    return (

        <div className='row pb-2 px-2' id="reference-panel">
            <div className='col-md-4'>
                <div className='row'>
                    <div className='col-md-4'>
                        <label for="fname" className="text-start control-label col-form-label-sm">Transaction Type:</label>
                    </div>
                    <div className="col-md-8">
                        <select className="select2 form-select form-select-sm shadow-none">
                            <option>Select</option>
                        </select>
                    </div>

                    <div className='col-md-4'>
                        <label for="fname" className="text-start control-label col-form-label-sm">Reference No.:</label>
                    </div>

                    <div className="col-md-8">
                        <input type="text" className="form-control form-control-sm" id="lastname" />
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <div className='row'>
                    <div className='col-md-4'>
                        <label for="fname" className="text-start control-label col-form-label-sm">Control No.:</label>
                    </div>

                    <div className="col-md-4 col-6">
                        <input type="text" className="form-control form-control-sm" id="lastname" />
                    </div>
                    <div className="col-md-4 col-6">
                        <input type="text" className="form-control form-control-sm" id="lastname" />
                    </div>

                    <div className='col-md-4'>
                        <label for="fname" className="text-start control-label col-form-label-sm">Trans Date:</label>
                    </div>

                    <div className="col-md-8">
                        <input type="date" className="form-control form-control-sm" id="lastname" />
                    </div>

                </div>
            </div>
            <div className='col-md-4'>
                <div className='row'>
                    <div className='col-md-4'>
                        <label for="fname" className="text-start control-label col-form-label-sm">Dept:</label>
                    </div>
                    <div className="col-md-8">
                        <select className="select2 form-select form-select-sm shadow-none">
                            <option>Select</option>
                        </select>
                    </div>

                    <div className='col-md-4'>
                        <label for="fname" className="text-start control-label col-form-label-sm">Batch No.:</label>
                    </div>

                    <div className="col-md-8">
                        <input type="text" className="form-control form-control-sm" id="lastname" />
                    </div>


                </div>

            </div>

        </div>

    )
}

export default ReferencePanel