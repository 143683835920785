import React from 'react'
import BusEmployerTab from '../../components/clientprofile/Tabs/BusEmployerTab'
import EducationTab from '../../components/clientprofile/Tabs/EducationTab'
import PersonalTab from '../../components/clientprofile/Tabs/PersonalTab'
import ContactsTab from '../../components/clientprofile/Tabs/ContactsTab'
import SpouseTab from '../../components/clientprofile/Tabs/SpouseTab'
import GroupsTab from '../../components/clientprofile/Tabs/GroupsTab'
import HistoryTab from '../../components/clientprofile/Tabs/HistoryTab'
import PledgesTab from '../../components/clientprofile/Tabs/PledgesTab'
import BillingsTab from '../../components/clientprofile/Tabs/BillingsTab'
import OthersTab from '../../components/clientprofile/Tabs/OthersTab'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import { useEffect } from 'react'

function ClientContent() {


  useEffect(() => {
    $('#cancelclient').css("display", "none");
    $('#deleteclient').css("display", "none");
    $('#saveclient').css("display", "none");
    $('#newclient').css("display", "");
    $('#searchclient').css("display", "");

  }, []);

  const clickCancel = () => {
    $('#cancelclient').css("display", "none");
    $('#newclient').css("display", "");
    $('#searchclient').css("display", "");
    $('#saveclient').css("display", "none");
    $('#deleteclient').css("display", "none");
  }

  const clickNew = () => {
    $('#cancelclient').css("display", "");
    $('#newclient').css("display", "none");
    $('#searchclient').css("display", "none");
    $('#saveclient').css("display", "");
    $('#deleteclient').css("display", "none");
  }

  const clickDelete = () => {

  }
  const clickSave = () => {

  }
  return (
    <div className="container-fluid">
      <div className='row'>
        <div className='col-12-md mb-2'>

          <Link to={"search"} id="searchclient" className='btn btn-info active btn-sm text-white  mx-1 btn-menu '>
            <i className="fa fa-search" aria-hidden="true"></i> Search
          </Link>

          <button onClick={clickSave} id="saveclient" className='btn btn-sm btn-primary mx-1 btn-menu'>
            <i className="fa fa-save" aria-hidden="true"></i> Save
          </button>

          <button onClick={clickDelete} id="deleteclient" className='btn btn-sm btn-warning mx-1 btn-menu'>
            <i className="fa fa-trash" aria-hidden="true"></i> Delete
          </button>

          <button onClick={clickNew} id="newclient" className='btn btn-sm btn-success text-white mx-1  btn-menu'>
            <i className="fa fa-plus" aria-hidden="true"></i> New
          </button>

          <button onClick={clickCancel} id="cancelclient" className='btn btn-sm btn-danger mx-1 btn-menu'>
            <i className="fa fa-times" aria-hidden="true"></i> Cancel
          </button>

        </div>
        <div className='col-12-md pb-4'>
          <div className='bg-light'>
            <div className='row'>
              <div className='col-md-12 mt-2'>
                <div className='row'>
                  <div className='col-md-2'>
                    <img id="img-client" src='../assets/images/users/1.jpg' className='border border-secondary mb-2' style={{ width: '80%' }} />
                  </div>
                  <div className='col-md-2 text-start'>
                    <h4 className="card-title">Juan Dela Cruz</h4>

                    <label className="control-label col-form-label-sm">100-1000-0001</label>
                  </div>
                  <div className='col-md-4' >
                    <div className='row' >
                      <div className='col-md-4 text-end'>
                        <label className="control-label col-form-label-sm">Reference ID:</label>
                      </div>
                      <div className='col-md-8'>
                        <input type="text" className="form-control form-control-sm" id="referenceid" />
                      </div>
                      <div className='col-md-4 text-end'>
                        <label className="control-label col-form-label-sm">Department:</label>
                      </div>
                      <div className='col-md-8'>
                        <select className="select2 form-select form-select-sm shadow-none">
                          <option>Select</option>
                        </select>
                      </div>

                      <div className='col-md-4 text-end'>
                        <label className="control-label col-form-label-sm">Client Type:</label>
                      </div>
                      <div className='col-md-8'>
                        <select className="select2 form-select form-select-sm shadow-none">
                          <option>Select</option>
                        </select>
                      </div>
                      <div className='col-md-4 text-end'>
                        <label className=" control-label col-form-label-sm">Account Type:</label>
                      </div>
                      <div className='col-md-8'>
                        <select className="select2 form-select form-select-sm shadow-none">
                          <option>Select</option>
                        </select>
                      </div>


                    </div>
                  </div>

                  <div className='col-md-4' >
                    <div className='row' >
                      <div className='col-md-4 text-end'>
                        <label className="control-label col-form-label-sm">Alert Type:</label>
                      </div>
                      <div className='col-md-8'>
                        <select className="select2 form-select form-select-sm shadow-none">
                          <option>Select</option>
                        </select>
                      </div>

                      <div className='col-md-4 text-end'>
                        <label className=" control-label col-form-label-sm">Date Open:</label>
                      </div>
                      <div className='col-md-8'>
                        <input type="date" className="form-control form-control-sm" id="referenceid" />
                      </div>

                      <div className='col-md-4 text-end'>
                        <label className=" control-label col-form-label-sm">Client Status:</label>
                      </div>
                      <div className='col-md-8'>
                        <select className="select2 form-select form-select-sm shadow-none">
                          <option>Select</option>
                        </select>
                      </div>

                      <div className='col-md-4 text-end'>
                        <label className=" control-label col-form-label-sm">Joint Type:</label>
                      </div>
                      <div className='col-md-8'>
                        <select className="select2 form-select form-select-sm shadow-none">
                          <option>Select</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <ul className="nav nav-tabs  mx-2" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a className="nav-link active" id="personal-tab" data-bs-toggle="tab" data-bs-target="#personal" type="button" role="tab" aria-controls="personal" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Personal</span></a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="employer-tab" data-bs-toggle="tab" data-bs-target="#employer" type="button" role="tab" aria-controls="employer" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Business/Employment.</span></a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="education-tab" data-bs-toggle="tab" data-bs-target="#education" type="button" role="tab" aria-controls="education" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Education</span></a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="contacts-tab" data-bs-toggle="tab" data-bs-target="#contacts" type="button" role="tab" aria-controls="contacts" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Contacts/IDs</span></a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="spouse-tab" data-bs-toggle="tab" data-bs-target="#spouse" type="button" role="tab" aria-controls="spouse" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Spouse.</span></a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="group-tab" data-bs-toggle="tab" data-bs-target="#group" type="button" role="tab" aria-controls="group" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Groups</span></a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="history-tab" data-bs-toggle="tab" data-bs-target="#history" type="button" role="tab" aria-controls="history" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">History</span></a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="pledges-tab" data-bs-toggle="tab" data-bs-target="#pledges" type="button" role="tab" aria-controls="pledges" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Pledges/Insurances</span></a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="others-tab" data-bs-toggle="tab" data-bs-target="#others" type="button" role="tab" aria-controls="others" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Others</span></a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="billing-tab" data-bs-toggle="tab" data-bs-target="#billing" type="button" role="tab" aria-controls="billing" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Billing</span></a>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="personal" role="tabpanel" aria-labelledby="personal-tab"><PersonalTab /></div>
                  <div className="tab-pane fade" id="employer" role="tabpanel" aria-labelledby="employer-tab"><BusEmployerTab /></div>
                  <div className="tab-pane fade" id="education" role="tabpanel" aria-labelledby="education-tab"><EducationTab /></div>
                  <div className="tab-pane fade" id="contacts" role="tabpanel" aria-labelledby="contacts-tab"><ContactsTab /></div>
                  <div className="tab-pane fade" id="spouse" role="tabpanel" aria-labelledby="spouse-tab"><SpouseTab /></div>
                  <div className="tab-pane fade" id="group" role="tabpanel" aria-labelledby="group-tab"><GroupsTab /></div>
                  <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab"><HistoryTab /></div>
                  <div className="tab-pane fade" id="pledges" role="tabpanel" aria-labelledby="pledges-tab"><PledgesTab /></div>
                  <div className="tab-pane fade" id="others" role="tabpanel" aria-labelledby="others-tab"><OthersTab /></div>
                  <div className="tab-pane fade" id="billing" role="tabpanel" aria-labelledby="billing-tab"><BillingsTab /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default ClientContent