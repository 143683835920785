import React from 'react'

function HistoryTab() {
  return (
    <div className='px-4 pt-3'>
      <div className='card'>
        <table
          class="styled-table"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Description</th>
              <th>Date</th>
              <th>Username</th>
              <th>Remarks</th>
              <th>PROM</th>
            </tr>
          </thead>
          <tbody>

          </tbody>

        </table>
      </div>
    </div>

  )
}

export default HistoryTab