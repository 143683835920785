import React from 'react'

function DashboardContent() {
   return (
      <div className="container-fluid">
         <div className='row'>
            <div className='col-md-12'>
               <label className='control-label col-form-label-sm'> API URL:</label> <label className='control-label col-form-label-sm border'><b> {window.localStorage.getItem('api_url')}</b></label>
            </div>
            <div className='col-md-12'>
               <label className='control-label col-form-label-sm' > API URL PREFIX:</label> <label className='control-label col-form-label-sm border'><b>{window.localStorage.getItem('api_prefix')} </b></label>
            </div>
            <div className='col-md-12'>
               <label className='control-label col-form-label-sm'> APP KEY: </label> <label className='control-label col-form-label-sm border'><b>{window.localStorage.getItem('app_key')}</b></label>
            </div>
            <div className='col-md-12'>
               <label className='control-label col-form-label-sm'> APP SECRET:</label> <label className='control-label col-form-label-sm border'><b>{window.localStorage.getItem('app_secret')}</b></label>
            </div>
         </div>

      </div>
   )
}

export default DashboardContent