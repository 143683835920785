import React from 'react'

function PresentTab() {
  return (
    <div className='row mx-4'>
            <div className='col-md-12'>
               
                <div className='w-100 text-start'>
                  <div className='row'>
                      <div className='col-md-10'>
                      <p className='text-secondary text-start control-label col-form-label-sm'>No Address</p>
                        <label className='text-start control-label col-form-label-sm'>Spang</label>
                        <label className='text-start control-label col-form-label-sm' >Moncada,</label>
                        <label className='text-start control-label col-form-label-sm'>Tarlac</label>
                      </div>
                      <div className='col-md-2'>
                      <button className='btn btn-sm'><i class="fa fa-pencil-square fa-2x" aria-hidden="true"></i></button>
                      </div>
                  </div>
                     
                </div>
            </div>

            <div className='col-md-12'>
            <br/>
                    <div className='row pt-2'>
                    <div className='col-md-4'>
                    <label
                            for="fname"
                            className="text-start control-label col-form-label-sm">Since (Year):</label>
                    </div>
                    <div className='col-md-8'>
                    <input
                            type="text"
                            className="form-control form-control-sm"
                            id="referenceid"
                          />
                    </div>
                 
                     
                    </div>
                    <div className='row pt-2'>
                        <div className='col-md-4'>
                        <label
                            for="fname"
                            className="text-start control-label col-form-label-sm">Type:</label>
                        </div>
                        <div className='col-md-8'>
                            <select className="select2 form-select form-select-sm shadow-none">
                                <option>Select</option>
                            </select>
                        </div>
                    
                          
                    </div>
            </div>


    </div>
  )
}

export default PresentTab