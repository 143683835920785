import React from 'react'

function TopBar() {
    return (
        <header className="topbar" data-navbarbg="skin5">
            <nav className="navbar fixed-top top-navbar navbar-expand-md navbar-dark">
                <div
                    className="navbar-collapse collapse"
                    data-navbarbg="skin5"
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav float-start me-auto">
                    <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="header-module-title"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="d-block d-md-none"
                ><i className="fa fa-plus"></i></span> IACCS X - LOGIN
              </a>
            </li>

                        <li id="navbarSupportedContent" className="draggable-log">
                            <br />
                            <br />
                        </li>

                    </ul>


                </div>
            </nav>
        </header>
    )
}

export default TopBar