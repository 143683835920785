import React from 'react'
import BusinessTab from '../AddressTab/BusinessTab'
import PresentTab from '../AddressTab/PresentTab'
import ProvencialTab from '../AddressTab/ProvencialTab'



function PersonalTab() {
  return (
    <div className='row px-4'  >
      <div className='col-md-7'>
        <div className='row mt-3'>
          <div className='col-md-2'>
            <label for="fname" className="text-start control-label col-form-label-sm">Account Name:</label>
          </div>
          <div className="col-md-10">
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-4'>
                <label for="fname" className="text-start control-label col-form-label-sm">Last Name:</label>
              </div>
              <div className="col-md-8">
                <input type="text" className="form-control form-control-sm" id="lastname" />
              </div>
              <div className='col-md-4'>
                <label for="fname" className="text-start control-label col-form-label-sm">First Name:</label>
              </div>
              <div className="col-md-8">
                <input type="text" className="form-control form-control-sm" id="lastname" />
              </div>
              <div className='col-md-4'>
                <label for="fname" className="text-start control-label col-form-label-sm">Middle Name:</label>
              </div>
              <div className="col-md-8">
                <input type="text" className="form-control form-control-sm" id="middlename" />
              </div>
              <div className='col-md-4'>
                <label for="fname" className="text-start control-label col-form-label-sm">Suffix:</label>
              </div>
              <div className="col-md-8">
                <input type="text" className="form-control form-control-sm" id="middlename" />
              </div>
              <div className='col-md-4'>
            <label for="fname" className="text-start control-label col-form-label-sm">Date of Birth:</label>
          </div>
          <div className="col-md-8">
            <input type="date" className="form-control form-control-sm" id="middlename" />
          </div>
          <div className='col-md-4'>
            <label for="fname" className="text-start control-label col-form-label-sm">Gender:</label>
          </div>
          <div className="col-md-8">
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>
          <div className='col-md-4'>
            <label for="fname" className="text-start control-label col-form-label-sm">Civil Status:</label>
          </div>
          <div className="col-md-8">
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>
          <div className='col-md-4'>
            <label for="fname" className="text-start control-label col-form-label-sm">Religion:</label>
          </div>
          <div className="col-md-8">
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>
            </div>
          </div>

          {/* ------------------------ */}
          <div className='col-md-6'>
            <div className='row'>
              <div className='col-md-4'>
                <label for="fname" className="text-start control-label col-form-label-sm">Month Income:</label>
              </div>
              <div className="col-md-8">
                <input type="number" className="form-control form-control-sm" id="lastname" />
              </div>
              <div className='col-md-4'>
            <label for="fname" className="text-start control-label col-form-label-sm">Profession:</label>
          </div>
          <div className="col-md-8">
            <input type="text" className="form-control form-control-sm" id="lastname" />
          </div>

          <div className='col-md-4'>
            <label for="fname" className="text-start control-label col-form-label-sm">Mail Address:</label>
          </div>
          <div className="col-md-8">
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>


          <div className='col-md-4'>
            <label for="fname" className="text-start control-label col-form-label-sm">DOSRI Tag:</label>
          </div>
          <div className="col-md-8">
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>
          <div className='col-md-4'>
            <label for="fname" className="text-start control-label col-form-label-sm">Weight:</label>
          </div>
          <div className="col-md-8">
            <input type="number" className="form-control form-control-sm" id="middlename" />
          </div>

          <div className='col-md-4'>
            <label for="fname" className="text-start control-label col-form-label-sm">Height:</label>
          </div>
          <div className="col-md-8">
            <input type="number" className="form-control form-control-sm" id="middlename" />
          </div>
          <div className='col-md-4'>
            <label for="fname" className="text-start control-label col-form-label-sm">Bloody Type:</label>
          </div>
          <div className="col-md-8">
            <input type="text" className="form-control form-control-sm" id="middlename" />
          </div>
          <div className="col-md-12">
            <input type="checkbox" className="text-end" id="middlename" />
            <label for="fname" className="text-start control-label col-form-label-sm mx-1">Include in Health Care</label>
          </div>
            </div>
          </div>
        </div>

      </div>
      <div className='col-md-5'>

        <div className='row'>
          <div className='col-md-12'>
            <div className='row px-3 pt-3'>
              <div className='col-md-4 btn btn-secondary'>
                <a href='#' className='text-white text-center'><i class="fa fa-users" aria-hidden="true"></i> Beneficiaries</a>
              </div>
              <div className='col-md-4 btn btn-secondary'>
                <a href='#' className='text-white text-center'><i class="fa fa-user" aria-hidden="true"></i> Personal References</a>
              </div>
              <div className='col-md-4 btn btn-secondary'>
                <a href='#' className='text-white text-center'><i class="fa fa-credit-card-alt" aria-hidden="true"></i> Credit/Bank References</a>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <ul className="nav nav-tabs mt-4 mx-2" id="myTab1" role="tablists">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" id="one-tab" data-bs-toggle="tab" data-bs-target="#one" type="button" role="tab" aria-controls="one" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Present</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link " id="two-tab" data-bs-toggle="tab" data-bs-target="#two" type="button" role="tab" aria-controls="two" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Provencial</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" id="three-tab" data-bs-toggle="tab" data-bs-target="#three" type="button" role="tab" aria-controls="three" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Business/Employ</span></a>
              </li>

            </ul>

            <div className="tab-content" id="myTab1Content">
              <div className="tab-pane fade show active" id="one" role="tabpanels" aria-labelledby="one-tab"> <PresentTab /> </div>
              <div className="tab-pane fade " id="two" role="tabpanels" aria-labelledby="two-tab"><ProvencialTab /></div>
              <div className="tab-pane fade " id="three" role="tabpanels" aria-labelledby="three-tab"><BusinessTab /></div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}

export default PersonalTab