import React, { useEffect, useState } from 'react'
import HeaderTopMenu from '../components/HeaderTopMenu'
import $ from 'jquery'
function Header(props) {

    const [isMini,setIsMini ] = useState(false);
    useEffect (() =>{ 
      
   

    $("body, .page-wrapper").trigger("resize");
    $(".page-wrapper").show();
  
    var setsidebartype = function () {
      var width = window.innerWidth > 0 ? window.innerWidth : this.screen.width;
      //  ORIGNAL 1170 to 1370
      if (width < 1370) {
        $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
        minibar();
        setIsMini(true) 
      } else {
        $("#main-wrapper").attr("data-sidebartype", "full");
        setIsMini(false) 
      }
    };
    $(window).ready(setsidebartype);
    $(window).on("resize", setsidebartype);


    },[window]);


    const  ClickCollapse = () => {
     $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
      // $("#main-wrapper").attr("data-sidebartype", "full");
      $("#main-wrapper").toggleClass("mini-sidebar");   
      if (isMini === false) {
      
        minibar();
      } else {
      
        fullbar();
      }
    }
    const minibar = () => {
      $(".sidebartoggler").prop("checked", !0);
      $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
      setIsMini(true) ;
    }
    const fullbar = () => {
      $(".sidebartoggler").prop("checked", !1);
      $("#main-wrapper").attr("data-sidebartype", "full");
      setIsMini(false);
    }
  

  return (
    <header className="topbar" data-navbarbg="skin5">
      <nav className="navbar fixed-top top-navbar navbar-expand-md navbar-dark">
        <div className="navbar-header" data-logobg="skin5">

          <a className="navbar-brand" href="/dashboard">

            <b className="logo-icon ps-2">

              <img
                src="../assets/images/logo-icon.png"
                alt="homepage"
                className="light-logo"
                width="25"
              />
            </b>

            <span className="logo-text ms-2">
              <img
                src="../assets/images/logo-text.png"
                alt="homepage"
                className="light-logo"
              />
            </span>

          </a>

          <a
            className="nav-toggler waves-effect waves-light d-block d-md-none"
            href="#">
            <i className="ti-menu ti-close"></i ></a>
        </div>
        <div
          className="navbar-collapse collapse"
       
          data-navbarbg="skin5"
          id="navbarSupportedContent"
          
        >
          <ul className="navbar-nav float-start me-auto startTopPoint">
            <li className="nav-item d-none d-lg-block">
              <a id="tooglerbutton"
            
                className="nav-link sidebartoggler waves-effect waves-light"
                href="#"
                onClick={ClickCollapse}
                data-sidebartype="mini-sidebar"
              ><i className="mdi mdi-menu font-24"></i></a>
            </li>
            <li className="">
              <b
                className="nav-link"
                
                id="header-module-title"
             
              
                
              >

                <span className="d-block d-md-none"
                ><i className="fa fa-plus"></i></span>  {props.title}
              </b>

            </li>
            <li className='grab'>   
              <div className='d-full-width '>
                <div className='draggable'>
                  <br />
                  <br />
                </div>
                
              </div>
            </li>
          </ul>

          <HeaderTopMenu/>
      
        </div>
      </nav>
    </header>
  )
}

export default Header