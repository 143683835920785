import React from 'react'
import GjTopMenu from '../../components/backoffice/general-journal/GjTopMenu'
import GLSLEntriesTab from '../../components/backoffice/general-journal/tabs/GLSLEntriesTab'
import ReferencePanel from '../../components/global/ReferencePanel'

function DisbursementContent() {
    return (
        <div className="container-fluid">
            <div className='row bg-light'>
                <div className='col-md-12'>
                    <GjTopMenu />
                </div>
                <div className='col-md-12'>
                    <ReferencePanel />
                </div>
                <div className='col-md-12'>
                    <ul className="nav nav-tabs  mx-2" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="glentries-tab" data-bs-toggle="tab" data-bs-target="#glentries" type="button" role="tab" aria-controls="glentries" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">GL/SL Entries</span></a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="cocidetails-tab" data-bs-toggle="tab" data-bs-target="#cocidetails" type="button" role="tab" aria-controls="cocidetails" aria-selected="false"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">COCI Details</span></a>
                        </li>
                    </ul>
                    <div className="tab-content bg-white" id="myTabContent">
                        <div className="tab-pane fade show active" id="glentries" role="tabpanel" aria-labelledby="glentries-tab"><GLSLEntriesTab /></div>
                        <div className="tab-pane fade" id="cocidetails" role="tabpanel" aria-labelledby="cocidetails-tab"></div>
                    </div>



                </div>

                <div className='col-md-12'>
                    <div className='row pt-3 px-3 pb-3'>
                        <div className='col-md-3'>
                            <label
                                for="fname"
                                className="text-start control-label col-form-label-sm ">Source of Document Reference Information:</label>
                        </div>
                        <div className='col-md-9'>
                            <input type="text" className="form-control form-control-sm" id="referenceid" />
                        </div>
                        <div className='col-md-3'>
                            <label
                                for="fname"
                                className="text-start control-label col-form-label-sm ">Description:</label>
                        </div>
                        <div className='col-md-9'>
                            <textarea className='form-control form-control-sm' rows={6}></textarea>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default DisbursementContent