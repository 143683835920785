import React from 'react'

function InsurancesPledgesTab() {
    return (
        <div className='card'>
                <table
                             class="styled-table"
                    >
                        <thead>
                            <tr>
                                <th>SL Type</th>
                                <th>Date</th>
                                <th>Insurance Plan</th>
                                <th>Auto</th>
                                <th>Batch</th>
                            </tr>
                        </thead>
                        <tbody>


                        </tbody>

                    </table>
                </div>
      
    )
}

export default InsurancesPledgesTab