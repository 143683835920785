import React from 'react'
import BillingPledgesTab from '../PledgesTab/BillingPledgesTab'
import ClientPledgesTab from '../PledgesTab/ClientPledgesTab'
import InsurancesPledgesTab from '../PledgesTab/InsurancesPledgesTab'

function PledgesTab() {
  return (
    <div className='px-4 pt-3'>

          <ul className="nav nav-tabs mt-4 mx-2" id="myTab4" role="tablists">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" id="clientpledges-tab" data-bs-toggle="tab" data-bs-target="#clientpledges" type="button" role="tab" aria-controls="clientpledges" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Client Pledges</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link " id="insurances-tab" data-bs-toggle="tab" data-bs-target="#insurances" type="button" role="tab" aria-controls="insurances" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Insurances</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link" id="billingpledges-tab" data-bs-toggle="tab" data-bs-target="#billingpledges" type="button" role="tab" aria-controls="billingpledges" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Billing Pledges</span></a>
              </li>

            </ul>
            
            <div className="tab-content" id="myTab4Content">
              <div className="tab-pane fade show active" id="clientpledges" role="tabpanels" aria-labelledby="clientpledges-tab"> <ClientPledgesTab/> </div>
              <div className="tab-pane fade " id="insurances" role="tabpanels" aria-labelledby="insurances-tab"><InsurancesPledgesTab/></div>
              <div className="tab-pane fade " id="billingpledges" role="tabpanels" aria-labelledby="billingpledges-tab"><BillingPledgesTab/></div>

            </div>


    </div>
  )
}

export default PledgesTab