import React from 'react'

import LoginContent from '../../contents/Other/LoginContent'
import TopBar from '../../contents/TopBar'
import '../../Login.css'
import vd from '../../dist/assets/video/videoplayback.mp4';

function Login() {
   
  return (
    
    <div
   
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin5"
      data-sidebartype="full"
      data-sidebar-position="absolute"
      data-header-position="absolute"
      data-boxed-layout="full"    
    >

<video  className='poster' id="videoBG" src={vd} autoPlay muted loop />

   

      <TopBar />

      <LoginContent />

    


    </div>
  )
}

export default Login