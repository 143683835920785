import React from 'react'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'

import FrontOfficeContent from '../../contents/FrontOffice/FrontOfficeContent'
import ReportViewer from '../../layouts/ReportViewer'

function FrontOffice() {
  return (
    <div
      id="main-wrapper"
      data-layout="vertical"
      data-navbarbg="skin5"
      data-sidebartype="full"
      data-sidebar-position="absolute"
      data-header-position="absolute"
      data-boxed-layout="full"
    >

      <Header title="Front Office" />
      <div>
        <Sidebar />
        <div className="page-wrapper">
          <FrontOfficeContent />
          <ReportViewer/>
        </div>
      </div>
    </div>
  )
}

export default FrontOffice