import React from 'react'
import ClientPanel from '../../global/ClientPanel'

function CashTopMenu() {
  return (
    <div className='row'>
    <div className='col-6 col-sm-6 col-md-6'>

        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav ">

                        <li class="nav-item mt-1">
                            <button className='btn btn-info btn-sm text-white '>
                                <i className="fa fa-search" aria-hidden="true"></i>  Search
                            </button>
                        </li>
                        {/* <li class="nav-item mt-1 ">
                            <button className='btn bg-cash active btn-sm text-white '>
                                <i className="fa fa-upload" aria-hidden="true"></i> Upload
                            </button>
                        </li> */}
                        {/* <li class="nav-item mt-1 ">
                            <button className='btn btn-danger btn-sm '>
                                <i className="fa fa-times" aria-hidden="true"></i> Cancel
                            </button>
                        </li> */}
                        {/* <li class="nav-item mt-1 ">
                            <label className='text-gray'>Field labeled  with (<b className='text-danger'>*</b>) are required</label>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>


    </div>
    <div className='col-6 col-sm-6 col-md-6 text-end'>
        <label className='bg-primary text-center text-white h2 btn-sm mt-2 w-50'>
            POSTED
        </label>
    </div>


        <ClientPanel/>
</div >
  )
}

export default CashTopMenu