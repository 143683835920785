import React from 'react'
import CreditLimitTab from '../SourceCreditTab/CreditLimitTab'
import SoureceTab from '../SourceCreditTab/SoureceTab'

function BusEmployerTab() {
  return (
    <div className='row px-4'>
      <div className='col-md-7'>
      <div className='row mt-3'>
          <div className='col-md-2'>
            <label for="fname" className="text-start control-label col-form-label-sm">Business :</label>
          </div>
          <div className="col-md-10">
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>
          <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">Nature of :</label>
          </div>
          <div className='col-md-4'>
             <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>
          <div className='col-md-2'>
              <label for="fname" className="control-label col-form-label-sm">Industrial Group:</label>
          </div>
          <div className='col-md-4'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
          </select>
          </div>

          <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">Nature Code :</label>
          </div>
          <div className='col-md-4'>
          <select className="select2 form-select form-select-sm shadow-none">
              <option>Sample</option>
          </select>
          </div>


          <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">Industry:</label>
          </div>
          <div className='col-md-4'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
          </select>
          </div>

          <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">Employee ID :</label>
          </div>
          <div className='col-md-4'>
             <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">Occ. Group:</label>
          </div>
          <div className='col-md-4'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
          </select>
          </div>

          <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">No. Employees:</label>
          </div>
          <div className='col-md-4'>
             <input type="number" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">Occupation:</label>
          </div>
          <div className='col-md-4'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
          </select>
          </div>
          <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">Date Hired:</label>
          </div>
          <div className='col-md-4'>
             <input type="date" className="form-control form-control-sm" id="referenceid" />
          </div>
        <div className='col-md-12'>
          <br/>
        </div>

        <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">Job Title:</label>
        </div>
        <div className='col-md-4'>
            <input type="number" className="form-control form-control-sm" id="referenceid" />
        </div>

        <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">Emp. Status:</label>
        </div>
        <div className='col-md-4'>
        <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
          </select>
        </div>

        <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">Employement Level:</label>
        </div>
        <div className='col-md-4'>
        <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
          </select>
        </div>
        <div className='col-md-2'>
              <label for="fname" className="text-start control-label col-form-label-sm">Since:</label>
        </div>
        <div className='col-md-4'>
            <input type="number" className="form-control form-control-sm" id="referenceid" />
        </div>

        </div>
      </div>
      <div className='col-md-5'>
          <div className='row'>
          <div className='col-md-12'>
            <ul className="nav nav-tabs mt-4 mx-2" id="myTab2" role="tablists">
              <li className="nav-item" role="presentation">
                <a className="nav-link active" id="source-tab" data-bs-toggle="tab" data-bs-target="#source" type="button" role="tab" aria-controls="source" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Source of Income</span></a>
              </li>
              <li className="nav-item" role="presentation">
                <a className="nav-link " id="credit-tab" data-bs-toggle="tab" data-bs-target="#credit" type="button" role="tab" aria-controls="credit" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Credit Limits</span></a>
              </li>
             

            </ul>

            <div className="tab-content" id="myTab2Content">
              <div className="tab-pane fade show active" id="source" role="tabpanels" aria-labelledby="source-tab"> <SoureceTab/> </div>
              <div className="tab-pane fade " id="credit" role="tabpanels" aria-labelledby="credit-tab"><CreditLimitTab/></div>
              

            </div>

          </div>
          </div>
      </div>

    </div>
  )
}

export default BusEmployerTab