import React from 'react'

function ClientPledgesTab() {
    return (
        <div className='card'>
                   <table
                             class="styled-table"
                    >
                        <thead>
                            <tr>
                                <th>SL TYPE</th>

                                <th>Amout</th>

                                <th>Reset</th>
                            </tr>
                        </thead>
                        <tbody>
                        

                        </tbody>

                    </table>
                </div>
     
    )
}

export default ClientPledgesTab