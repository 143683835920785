import React from 'react'

function ContactsTab() {
  return (
    <div className='row px-4 pt-3'>
      <div className='col-md-4'>
        <div className='row'>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Home Tel. No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>



          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Cellphone No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Business No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Spouse Cell/Tel No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Province Telephone No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Other Contact No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Email Address:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">SMS Brodcast No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Social Media Account.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>




        </div>
      </div>
      <div className='col-md-4'>
        <div className='row'>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Bank Name:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Bank Account No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">SSS No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">GSIS No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">PhilHealth No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Pagibig No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">TIN No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">CTC No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">CTC Date Issued:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="date" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">CTC Place Issued:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

        </div>
      </div>

      <div className='col-md-4'>

        <div className='row'>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Driver`s License:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">NSO No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Voter`s ID No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Other ID No.:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


        </div>


      </div>

    </div>
  )
}

export default ContactsTab