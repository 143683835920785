import React from 'react'
import ClientPanel from '../global/ClientPanel'

function ClientInfoOF() {
    return (
        <div className='col-md-12 bg-light pt-1' >
            <div className='row'>
                <div className='col-12-md '>

                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-6'>
                            <button className='btn btn-sm btn-info text-white mx-1'>
                                <i className="fa fa-search" aria-hidden="true"></i>  Search
                            </button>
                            <button className='btn btn-sm btn-success text-white mx-1'>
                                <i className="fa fa-save" aria-hidden="true"></i> Save
                            </button>
                            <button className='btn btn-sm btn-danger mx-1'>
                                <i className="fa fa-times" aria-hidden="true"></i> Cancel
                            </button>

                        </div>
                        <div className='col-12 col-sm-6 col-md-6 text-start'>
                            <div className='row mt-2'>
                                <div className='col-md-6 col-12 text-end'>
                                    <div className='row'>
                                        <div className='col-2 col-md-6'> Total</div>
                                        <div className='col-10 col-md-6'>
                                            <label className="form-control form-control-sm text-end w-100" id="referenceid" > 0.00 </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 text-end'>
                                    <div className='row'>

                                        <div className='col-2 col-md-6'> Time</div>
                                        <div className='col-10 col-md-6'>
                                            <label className="form-control form-control-sm text-end w-100" id="referenceid" > </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>

                <ClientPanel />
            </div>
        </div>
    )
}

export default ClientInfoOF