import React from 'react'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'

import GeneralJournalContent from '../../contents/BackOffice/GeneralJournalContent'
import ReportViewer from '../../layouts/ReportViewer'

function GeneralJournal() {
  return (
    <div
    id="main-wrapper"
    data-layout="vertical"
    data-navbarbg="skin5"
    data-sidebartype="full"
    data-sidebar-position="absolute"
    data-header-position="absolute"
    data-boxed-layout="full"
>
    <Header title="General Journal"  />
    <div>
    <Sidebar />

    <div className="page-wrapper">

        <GeneralJournalContent/>
        <ReportViewer/>
    </div>
</div>
</div>
  )
}

export default GeneralJournal