import React from 'react'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'

import BillPaymentContent from '../../contents/BackOffice/BillPaymentContent'
import ReportViewer from '../../layouts/ReportViewer'

function BillsPayment() {
    return (
        <div
            id="main-wrapper"
            data-layout="vertical"
            data-navbarbg="skin5"
            data-sidebartype="full"
            data-sidebar-position="absolute"
            data-header-position="absolute"
            data-boxed-layout="full"
        >
            <Header title="Bills Payment" />
            <div>
            <Sidebar />
            <div className="page-wrapper">
                <BillPaymentContent />    
                <ReportViewer/>  
            </div>
</div>
        </div>
    )
}

export default BillsPayment