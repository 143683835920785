import React from 'react'

function OthersTab() {
  return (
    <div className='row px-4 pt-3'>

      <div className='col-md-6'>
        <div className='row'>
          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Recruited By:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Number of Recruits:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Meal Allowance:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>


          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Sales Credit Limit:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="number" className="form-control form-control-sm text-end" id="referenceid" />
          </div>

          <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Add-On Limit:
            </label>
          </div>

          <div className='col-md-8'>
            <div className='row'>
              <div className='col-md-6'>
                <input type="number" className="form-control form-control-sm text-end" id="referenceid" />
              </div>
              <div className='col-md-6'>
                <input type="date" className="form-control form-control-sm text-start" id="referenceid" />
              </div>
            </div>
          </div>

        <div className='col-md-12'>
          <br/>
        </div>

        <div className='col-md-4'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Payroll Production Limit:
            </label>
          </div>

          <div className='col-md-8'>
            <input type="number" className="form-control form-control-sm text-end" id="referenceid" />
          </div>

        </div>


      </div>

      <div className='col-md-6'>
        <div className='row'>
              <div className='col-md-12'>
              <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Remarks:
            </label>
              </div>
            
              <div className='col-md-12'>
                <textarea className='form-control form-control-sm w-100 h-100' rows={6} />
              </div>
        </div>
   
      </div>
    </div>
  )
}

export default OthersTab