import React from 'react'

function BillingPledgesTab() {
  return (
    <div className='card'>
         <table
                             class="styled-table"
                    >
                <thead>
                    <tr>
                        <th>SL Type</th>
                        <th>Amount</th>
                        <th>Setup</th>
                        <th>Start</th>
                        <th>Until</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>

            </table>
        </div>

  )
}

export default BillingPledgesTab