import React from 'react'

function CustomReportContent() {
  return (
    <div className="container-fluid">
<h4>Customized Reports</h4>
<div className='card'>

        <div className='card-body'>

                    <div className="">
                    <table
                 
                      className="styled-table w-100"
                    >
                      <thead>
                        <tr>
                          <th>SEQ</th>           
                          <th>Description</th>
                          <th>Remarks</th>
                          <th>Title</th>
                          <th>Created By</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody className='table-grid-d'>
                        <tr>
                            <td>99</td>
                            <td>CIC mandatory field checker</td>
                            <td>CIC mandatory field checker</td>
                            <td>CIC mandatory field checker</td>
                            <td>Admin</td>
                            <td>2023-1-3 3:35:22</td>
                        </tr>
                        <tr>
                            <td>99</td>
                            <td>CIC mandatory field checker</td>
                            <td>CIC mandatory field checker</td>
                            <td>CIC mandatory field checker</td>
                            <td>Admin</td>
                            <td>2023-1-3 3:35:22</td>
                        </tr>
                        <tr>
                            <td>99</td>
                            <td>CIC mandatory field checker</td>
                            <td>CIC mandatory field checker</td>
                            <td>CIC mandatory field checker</td>
                            <td>Admin</td>
                            <td>2023-1-3 3:35:22</td>
                        </tr>
                      </tbody>
                   
                    </table>
                  </div>
        </div>

    </div>

    </div>
  )
}

export default CustomReportContent