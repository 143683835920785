import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import $ from 'jquery';
import { Button } from 'react-bootstrap';

function HeaderTopMenu() {

    const PreviewReportClick = () => {
      const dt = $(".panel-right-wrap").css("transform");
          
        if (dt.toString() ==='matrix(1, 0, 0, 1, 0, 0)') {
            $(".panel-right-wrap").css("transform", "");
           
        } else {
            
            $(".panel-right-wrap").css("transform", "translateX(0%)");

        }

        $(".panel-right-wrap").css("width", "50%");
        var docFile = $('#view-icon_arrow');
        docFile.addClass('fa-arrow-left');
        docFile.removeClass('fa-arrow-right');

    }
    const navDropdownTitle = (<span title='Setup' className='fa fa-cog fa-lg' style={{ fontSize: "20px" }}></span>);
    const sltitle = (<span title='SL Subsidiary Ledger' className='sub-header' >Subsidiary Ledger  <i className="text-right fa fa-chevron-down" style={{ float: "right" }} ></i> </span>)
    const clientgrouptitle = (<span title='Client Group' className='sub-header' >Client Groups <i className="text-right fa fa-chevron-down" style={{ float: "right" }} ></i> </span>)
    const loanrtitle = (<span title='Loan Related' className='sub-header' >Loan Related <i className="text-right fa fa-chevron-down" style={{ float: "right" }} ></i> </span>)
    const checkbooktitle = (<span title='Check Book' className='sub-header' >Check Books <i className="text-right fa fa-chevron-down" style={{ float: "right" }} ></i> </span>)

    return (
        <>
            <ul className="navbar-nav float-end endTopPoint ">
                <li className="nav-item dropdown">
                    <Button variant="transparent" className='w-25 text-white' title='Report Viewer' onClick={PreviewReportClick}><i class="fa fa-print fa-lg text-white" aria-hidden="true"></i></Button>
                </li>
                <li className="nav-item dropdown">
                    <NavDropdownMenu title={navDropdownTitle} eventKey={3} id="collasible-nav-dropdown" placeholder='setup' alignRight >

                        <NavDropdown.Item href="#action/3.1" className="text-dark" > Chart of Accounts</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2" className="text-dark">CONSO Chart of Accounts</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3" className="text-dark">Code Storage</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.4" className="text-dark">Holidays</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.5" className="text-dark">Email Settings</NavDropdown.Item>

                        <NavDropdownMenu title={sltitle} id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">CLass</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.1">Type</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.1">Entry Type</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.1">Status</NavDropdown.Item>
                        </NavDropdownMenu>

                        <NavDropdownMenu title={clientgrouptitle} id="collasible-nav-dropdown" alignRight   >
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <DropdownSubmenu href="#action/3.7" title="Text to show">
                                <NavDropdown.Item href="#action/8.1">Sub 1</NavDropdown.Item>
                                <DropdownSubmenu href="#action/3.7" title="Text to show">
                                    <NavDropdown.Item href="#action/9.1">Sub 2</NavDropdown.Item>
                                </DropdownSubmenu>
                            </DropdownSubmenu>
                        </NavDropdownMenu>

                        <NavDropdownMenu title={loanrtitle} id="collasible-nav-dropdown" alignRight   >
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <DropdownSubmenu href="#action/3.7" title="Text to show">
                                <NavDropdown.Item href="#action/8.1">Sub 1</NavDropdown.Item>
                                <DropdownSubmenu href="#action/3.7" title="Text to show">
                                    <NavDropdown.Item href="#action/9.1">Sub 2</NavDropdown.Item>
                                </DropdownSubmenu>
                            </DropdownSubmenu>
                        </NavDropdownMenu>

                        <NavDropdownMenu title={checkbooktitle} id="collasible-nav-dropdown" alignRight   >
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <DropdownSubmenu href="#action/3.7" title="Text to show">
                                <NavDropdown.Item href="#action/8.1">Sub 1</NavDropdown.Item>
                                <DropdownSubmenu href="#action/3.7" title="Text to show">
                                    <NavDropdown.Item href="#action/9.1">Sub 2</NavDropdown.Item>
                                </DropdownSubmenu>
                            </DropdownSubmenu>
                        </NavDropdownMenu>
                    </NavDropdownMenu>
                </li>

                <li className="nav-item dropdown">
                    <Dropdown>
                        <Dropdown.Toggle variant="transparent" className='w-25' id="dropdown-basic">
                            <i className="fa fa-pie-chart fa-lg text-white" title='Reports' aria-hidden="true"> </i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </li>

                <li className="nav-item dropdown">

                    <Dropdown>
                        <Dropdown.Toggle variant="transparent" className='w-25' id="dropdown-basic">
                            <i className="fa fa-wrench fa-lg text-white" title='Tools' aria-hidden="true"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>

                <li className="nav-item dropdown" >
                    <Dropdown>
                        <Dropdown.Toggle variant="transparent" className='w-25' id="dropdown-basic">
                            <i className="fa fa-user fa-lg text-white" title='User Setting' aria-hidden="true"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#/action-1">
                                <i class="fa fa-cogs me-1 ms-1" aria-hidden="true"></i>
                                Account
                                Setting
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="/login"><i className="fa fa-power-off me-1 ms-1"></i>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>


                </li>

            </ul>

        </>
    )
}

export default HeaderTopMenu