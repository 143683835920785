import React from 'react'
import ReferencePanel from '../../global/ReferencePanel'
import CashDom from '../ReceiptTab/CashDom'
import CociDetails from '../ReceiptTab/CociDetails'
import GlEntry from '../ReceiptTab/GlEntry'

function TransferToTab() {
    return (
        <div className='row pt-2'>
            <div className='col-md-12'>
                <ReferencePanel />
            </div>
            <div className='col-md-12 border-top'>

                <div className='row'>
                    <div className='col-md-4'>

                        <table className='w-100'>
                            <thead>
                                <tr>
                                    <th className='col-6'>

                                    </th>
                                    <th className='col-6'>

                                    </th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                <tr>
                                    <td>
                                        <div className='row px-3'>
                                            <div className='col-md-12 text-start'>Source:</div>

                                        </div>
                                    </td>
                                    <td>
                                        <select className="select2 form-select form-select-sm shadow-none">
                                            <option>Select</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td> <div className='row px-3'>
                                        <div className='col-md-12 text-start'>Destination:</div>
                                    </div>
                                    </td>
                                    <td>
                                        <input type="number" className="form-control form-control-sm text-end" />
                                    </td>
                                </tr>
                                <tr>
                                    <td> <div className='row px-3'>
                                        <div className='col-md-12 text-start'>User:</div>

                                    </div>
                                    </td>
                                    <td>
                                        <input type="text" className="form-control form-control-sm text-end" />
                                    </td>
                                </tr>
                                <tr className='border-top'>
                                    <td> <div className='row px-3'>
                                        <div className='col-md-12 text-end'><b>CASH</b>:</div>
                                    </div>
                                    </td>
                                    <td>
                                        <input type="number" className="form-control form-control-sm text-end" />
                                    </td>
                                </tr>
                                <tr >
                                    <td> <div className='row px-3'>
                                        <div className='col-md-12 text-end'><b>COCI</b>:</div>

                                    </div>
                                    </td>
                                    <td>
                                        <input type="number" className="form-control form-control-sm text-end" />
                                    </td>
                                </tr>

                                <tr >
                                    <td> <div className='row px-3'>
                                        <div className='col-md-12 text-end'><b>OTHER COCI</b>:</div>

                                    </div>
                                    </td>
                                    <td>
                                        <input type="number" className="form-control form-control-sm text-end" />
                                    </td>
                                </tr>

                                <tr >
                                    <td> <div className='row px-3'>
                                        <div className='col-md-12 text-end'><b>TOTAL</b>:</div>
                                    </div>
                                    </td>
                                    <td>
                                        <input type="number" className="form-control form-control-sm text-end" readOnly />
                                    </td>
                                </tr>

                            </tbody>


                        </table>
                        <table className='w-100 mt-1'>
                            <thead>
                                <tr>
                                    <th>
                                        <div className='bg-total text-white text-center'>
                                            Explanation
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                <tr>
                                    <td>
                                        <textarea className='form-control form-control-sm' placeholder='Write Explanation' rows={2} />


                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className='col-md-8'>
                        <div className='col-md-12'>

                        </div>
                        <div className='col-md-12'>
                            <ul className="nav nav-tabs  mx-2" id="myTab1" role="tablists">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="glentry-tab" data-bs-toggle="tab" data-bs-target="#glentry" type="button" role="tab" aria-controls="glentry" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">GL/SL Entry</span></a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link " id="cocidetails-tab" data-bs-toggle="tab" data-bs-target="#cocidetails" type="button" role="tab" aria-controls="cocidetails" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">COCI Details</span></a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="cashdom-tab" data-bs-toggle="tab" data-bs-target="#cashdom" type="button" role="tab" aria-controls="cashdom" aria-selected="true"><span className="hidden-sm-up"></span> <span className="hidden-xs-down">Cash Denomiation</span></a>
                                </li>

                            </ul>

                            <div className="tab-content" id="myTab1Content">
                                <div className="tab-pane fade show active" id="glentry" role="tabpanels" aria-labelledby="glentry-tab"><GlEntry /></div>
                                <div className="tab-pane fade " id="cocidetails" role="tabpanels" aria-labelledby="cocidetails-tab"><CociDetails /></div>
                                <div className="tab-pane fade " id="cashdom" role="tabpanels" aria-labelledby="cashdom-tab"><CashDom /></div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TransferToTab