import React from 'react'

function CociPanel() {
    return (
        <div className='col-md-4 border'>
            <div className='row'>
                <div className='col-md-12 bg-coci text-center  text-white'>
                <b>COCI</b>
                </div>
                <div className='col-md-12 mt-3'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <h6 className='text-gray'>BEGINING BALANCE:</h6>
                        </div>
                        <div className='col-md-6'>
                            <h4 className='py-1 px-4 border-top border-secondary text-end'>3,500.00</h4>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 '>
                    <div className='row'>
                        <div className='col-md-6'>
                            <h5 className='text-gray'>IN:</h5>
                        </div>
                        <div className='col-md-6'>
                            <h5 className='py-1 px-2 border-top border-secondary text-end'>2,000.00</h5>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <h5 className='text-gray'>OUT:</h5>
                        </div>
                        <div className='col-md-6'>
                            <h5 className='py-1 px-2 border-top border-secondary text-end'>1,500.00</h5>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 mt-3'>
                    <div className='row'>
                        <div className='col-md-6'>
                        <h6 className='text-gray'>PENDING BALANCE:</h6>
                        </div>
                        <div className='col-md-6'>
                        <h4 className='py-1 px-4 border-top border-secondary text-end'>350.00</h4>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 mt-1'>
               
                    <div className='row'>
                        <div className='col-md-6'>
                            <h5 className='text-gray'>IN:</h5>
                        </div>
                        <div className='col-md-6'>
                            <h5 className='py-1 px-2 border-top border-secondary text-end'>300.00</h5>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 mt-1 '>
         
                    <div className='row'>
                        <div className='col-md-6'>
                            <h5 className='text-gray'>OUT:</h5>
                        </div>
                        <div className='col-md-6'>
                            <h5 className='py-1 px-2 border-top border-secondary text-end'>50.00</h5>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 mt-3'>       
                    <div className='row'>
                        <div className='col-md-6'>
                            <h6 className='text-gray'>ENDING BALANCE:</h6>
                  
                        </div>
                        <div className='col-md-6'>
                            <h4 className='py-1 px-4 border-top border-secondary text-end'>3,150.00</h4>
                        </div>
                    </div>
                </div>

              

            </div>
        </div>
    )
}

export default CociPanel