import React from 'react'

function EducationTab() {
  return (
    <div className='row px-4'>
      <div className='col-md-6'>
        <div className='row mt-3'>
          <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">School Attainment:
            </label>
          </div>

          <div className='col-md-9'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>

          <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">School Last Attended:
            </label>
          </div>

          <div className='col-md-9'>
          <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-12'>
              <br/>
          </div>


          <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">School:
            </label>
          </div>

          <div className='col-md-9'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>


          <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Section:
            </label>
          </div>

          <div className='col-md-9'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>
        </div>
      </div>


    </div>
  )
}

export default EducationTab