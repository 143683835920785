import React from 'react'
import Header from '../layouts/Header'
import Sidebar from '../layouts/Sidebar'

import DashboardContent from '../contents/DashboardContent'
import ReportViewer from '../layouts/ReportViewer'

function Dashboard() {
    return (
        <div
            id="main-wrapper"
            data-layout="vertical"
            data-navbarbg="skin5"
            data-sidebartype="full"
            data-sidebar-position="absolute"
            data-header-position="absolute"
            data-boxed-layout="full"
        >

            <Header title="Dashboard" />
            
            <div>
                <Sidebar />
                <div className="page-wrapper ">
                    <DashboardContent />
                    <ReportViewer/>
                </div>
            </div>
        </div>
    )
}

export default Dashboard