import React from 'react'

function TotalPanel() {
  return (
    <div className='col-md-4 border'>
      <div className='row'>
        <div className='col-md-12 bg-total text-center  text-white'>
        <b>TOTAL</b>
        </div>
        <div className='col-md-12 mt-3'>
          <div className='row'>
            <div className='col-md-6'>
              <h6 className='text-gray'>BEGINING BALANCE:</h6>
            </div>
            <div className='col-md-6'>
              <h4 className='py-1 px-4 border-top border-secondary text-end'>6,500.00</h4>
            </div>
          </div>
        </div>
        <div className='col-md-12 '>
          <div className='row'>
            <div className='col-md-6'>
              <h5 className='text-gray'>IN:</h5>
            </div>
            <div className='col-md-6'>
              <h5 className='py-1 px-2 border-top border-secondary text-end'>4,000.00</h5>
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-md-6'>
              <h5 className='text-gray'>OUT:</h5>
            </div>
            <div className='col-md-6'>
              <h5 className='py-1 px-2 border-top border-secondary text-end'>2,500.00</h5>
            </div>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <div className='row'>
            <div className='col-md-6'>
              <h6 className='text-gray'>PENDING BALANCE:</h6>
            </div>
            <div className='col-md-6'>
              <h4 className='py-1 px-4 border-top border-secondary text-end'>1050.00</h4>
            </div>
          </div>
        </div>
        <div className='col-md-12 mt-1'>
          <div className='row'>
            <div className='col-md-6'>
              <h5 className='text-gray'>IN:</h5>
            </div>
            <div className='col-md-6'>
              <h5 className='py-1 px-2 border-top border-secondary text-end'>800.00</h5>
            </div>
          </div>
        </div>
        <div className='col-md-12 mt-1 '>
          <div className='row'>
            <div className='col-md-6'>
              <h5 className='text-gray'>OUT:</h5>
            </div>
            <div className='col-md-6'>
              <h5 className='py-1 px-2 border-top border-secondary text-end'>250.00</h5>
            </div>
          </div>
        </div>
        <div className='col-md-12 mt-3'>
          <div className='row'>
            <div className='col-md-6'>
              <h6 className='text-gray'>ENDING BALANCE:</h6>
            </div>
            <div className='col-md-6'>
              <h4 className='py-1 px-4 border-top border-secondary text-end'>5,450.00</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TotalPanel