import React from 'react'
import GetCurrentDateTime from '../../../utils/GetCurrentDateTime'
import CashPanel from '../SummaryTab/CashPanel'
import CociPanel from '../SummaryTab/CociPanel'
import TotalPanel from '../SummaryTab/TotalPanel'

function SummaryTab() {
    return (
        <div className='row px-4 pt-3 mb-3'>

            <div className='col-md-12' >
                <div className='row'>
                    <div className='col-md-6'>
                        <h4>Juan Dela Cruz</h4>
                        <h6>Cashier 32 | Admin</h6>
                    </div>
                    <div className='col-md-6 text-end'>
                        <GetCurrentDateTime />
                    </div>
                </div>

            </div>
            <div className='col-md-12 mb-1'>
                <div className='row'>
                    <CashPanel />
                    <CociPanel />
                    <TotalPanel />


                </div>
            </div>  




        </div>
    )
}

export default SummaryTab