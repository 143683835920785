import React from 'react'

function GroupsTab() {
  return (
    <div className='row px-4 pt-3'>

      <div className='col-md-6'>

        <div className='row'>

          <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Sectorial Group:
            </label>
          </div>

          <div className='col-md-9'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Select</option>
            </select>
          </div>

          <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Geographical Group:
            </label>
          </div>

          <div className='col-md-9'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-12'>
            <br />
          </div>

          <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Restriction Group:
            </label>
          </div>

          <div className='col-md-9'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Default</option>
            </select>
          </div>

          <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Client Grouping:
            </label>
          </div>

          <div className='col-md-9'>
            <input type="text" className="form-control form-control-sm" id="referenceid" />
          </div>

          <div className='col-md-12'>
              <br/>
          </div>

          <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Loan Availment Type:
            </label>
          </div>

          <div className='col-md-9'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Default</option>
            </select>
          </div>
        </div>

      </div>

      <div className='col-md-6'>
      <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">DepEd Coding:
            </label>
            <div className='row'>
            <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Region Code:
            </label>
          </div>

          <div className='col-md-9'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Default</option>
            </select>
          </div>

          <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Division Code:
            </label>
          </div>

          <div className='col-md-9'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Default</option>
            </select>
          </div>


          <div className='col-md-3'>
            <label
              for="school-attainment"
              className="text-start control-label col-form-label-sm">Station Code:
            </label>
          </div>

          <div className='col-md-9'>
            <select className="select2 form-select form-select-sm shadow-none">
              <option>Default</option>
            </select>
          </div>



            </div>

      </div>
    </div>
  )
}

export default GroupsTab